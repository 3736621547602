.game-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    /* margin-top: 0.625rem; */
    /* height: 100dvh; */
    /* background-color: rgba(0, 0, 0, .7); */
    /* height: 100%; */
}

.game-stats{
    padding: 0.625rem 0;
    display: flex;
    min-height: 30px;
    font-size: 0.125rem;
    line-height: 1;
    text-align: center;
    justify-content: space-between;
    width: 100%;
}

.game-stats > div{
    margin-right: 0.625rem;
}

.game-board{
    position: relative;
    width: calc(100cqw - (1.1875rem * 2));
    max-width: 450px;
    box-sizing: 0;
    z-index: 0;
    box-shadow:inset 0 0 50px black;
    background: url(../../../public/assets/images/wood-bg.jpg);
    height: fit-content;
    border:inset 0.75rem;
    border-image: url(../../../public/assets/images/wood-bg.jpg) 30 / 1.1875rem round;
}

.tile{
    position: absolute;
    box-sizing: 0;
    user-select: none;
    text-shadow: 0, 0, 5px black;
    /* border: 1px solid red; */
    color: white;
}

.tile div{
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
}

.movable-tiles{
    font-weight: bolder;
    /* border: 1px solid red;
    font-size: 0.6rem; */
    color: red;
    z-index: 1;
}

.movable-tiles > div{
    height: 100%;
}

.movable-tiles:hover{
    box-shadow: inset 0 0 10px black;
}

.empty-tile{
    background-image: none;
    background-color: rgba(0, 0, 0, .5);
    z-index: 0;
    box-shadow: inset 0 0 10px black;
    color: orange !important;
}