
#picpazz-layout{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100dvh;
}

header{
    padding: 1.25rem;
}

h1.picpazz-logo{
    font-family: 'Rubik Vinyl', cursive;
    font-size: 2rem;
    margin: 1.875rem 0;
    color: white;
}

section{
    display: flex;
    align-self: stretch;
    flex-grow: 1;
}

nav{
    width: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
}

section nav{
    
}

section nav ul, section nav li{
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
}

section nav li{
    list-style: none;
    text-align: center;
}

section nav a{
    display: block;
    padding: 1.25rem;
    text-decoration: none;
    font-family: 'Nunito', sans-serif;
    font-weight: bolder;
    color: rgb(255, 217, 0);
}

section nav span{
    padding: 0.625rem;
    display: block;
}

main{
    /* border: 1px solid black; */
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}