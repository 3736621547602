#game{
    display: flex;
    flex-direction: column;
    background-color: rgb(54, 1, 1);
    width: 100%;
    height: 99.9dvh;
}

.play-stats{
    display: flex;
    /* justify-content: center; */
    align-items: baseline;

    background-image: linear-gradient(rgb(245, 163, 29), rgb(254, 218, 0));
    /* width: 100%; */
    height: 45px;
    margin-bottom: 4.375rem;
    padding-top: 2.5rem;
    padding-left: 1.875rem;
    /* border-bottom: groove 5px rgb(53, 3, 3); */
    border-bottom: 5px solid rgb(255, 217, 0);
    z-index: 2;
}

.play-stats > div{
    text-align: center;
    padding: 1.25rem;
    /* height: 150px; */
    margin:0 0.625rem 1.25rem 0.625rem;
    background-image: linear-gradient( rgb(87, 9, 9),  rgb(145, 9, 9));
    border-radius: 20px;
    color: white;
    border: solid rgb(245, 163, 29) 5px;
}

.play-stats+div{
    /* flex-grow: 1; */
    display: flex;
    /* flex-direction: column; */
    padding-left: calc(1.875rem + 0.625rem);
    /* justify-content: center; */
    /* margin-top: -30px; */
}

.play-stats+div > div{
    /* border: 1px solid red; */
}