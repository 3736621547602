
@import url('https://fonts.googleapis.com/css2?family=Lilita+One&family=Merriweather+Sans:wght@800&family=Nunito:wght@600&family=Rubik+Vinyl&display=swap');

*{
  box-sizing: 0;
}

body {
  margin: 0;
}

h1, h2, h3, h4{
  padding: 0;
  margin: 0;
}
